/**
 * @author Johann Kowalski (traal-devel) <devel@traal.ch>
 */
import { Component, ElementRef, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { EpgService } from 'src/app/service/epg.service';
import { TvfAuthService } from 'src/app/service/tvf-auth.service';
import { AssetItem, AssetsListResponse, ChannelStreamResponse } from 'tvf-rest-client';
import { ChannelProgram } from 'projects/tvf-rest-client/src';
import { ShoutcastStreamPipe } from 'src/app/shared/pipe/shoutcast-stream.pipe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tile-radio',
  templateUrl: './epg-tile-radio.component.html',
  styleUrls: ['./epg-tile-radio.component.css']
})
export class EpgTileRadioComponent implements OnInit, OnDestroy {

  /* member variables */
  detailIndex = -1;

  channelList = [];
  epgCurrentList: ChannelProgram[];

  @ViewChild('audioElem', { static: false }) audioElementRef: ElementRef;

  isLoading = true;


  /* constructors */
  constructor(
    private tvfAuthService: TvfAuthService,
    private epgService: EpgService,
    private shoutcastStreamPipe: ShoutcastStreamPipe,
    private ngZone: NgZone,
    private renderer: Renderer2, 
  ) { }

  
  
  /* methods */
  ngOnInit(): void {
    // ensure that access token is present in the http headers
    this.tvfAuthService.checkAutoLogin();

    this.initChannelList();
  }

  ngOnDestroy() {
    console.log("destroy");
    this.closeChannel();
  }

  initChannelList(): void {
    this.epgService
      .getRadioChannelListOrdered()
      .subscribe((value: AssetsListResponse) => {
        this.channelList = [
          ...this.channelList,
          ...value.data,
        ];
      }
    );
  }

  closeChannel() {
    this.channelList.splice(this.detailIndex, 1);
    this.detailIndex = -1;
    const audio = this.audioElementRef.nativeElement as HTMLAudioElement;
    // audio.pause();
    audio.src = ""; // Stop audio.
  }

  showChannel(index: number): void {
    const tmpRef = this.channelList[index];
    this.isLoading = true;
    if (this.detailIndex > -1) {
      this.channelList.splice(this.detailIndex, 1);
      index = index - 1;
    }
    this.detailIndex = (Math.floor((index) / 8)) * 8;
    this.channelList.splice(this.detailIndex, 0, tmpRef);
    this.channelList = [
      ...this.channelList,
    ];
    // Workaround: Wait, till video element is added to dom tree.
    this.ngZone.runOutsideAngular(() => {
      const audio = this.audioElementRef.nativeElement as HTMLAudioElement;
      this.initAudio(
        this.channelList[this.detailIndex],
        audio
      );
    });
  }

  private initAudio(
    assetItem: AssetItem,
    audio: HTMLAudioElement
  ): void {
    const rShoutcastStream = this.shoutcastStreamPipe.transform(assetItem.resources);

    this.epgService.getChannelStream(
      assetItem.id,
      rShoutcastStream.id
    ).subscribe((response: ChannelStreamResponse) => {
      const mapUrl = environment.radio_map_url;
      audio.src = response.data.replace(mapUrl.origin_http, mapUrl.origin_https);
      console.log("Audio-Source:", audio.src);
      audio.load();
      audio.play()
          .catch(() => { /* discard runtime error */ });
    });
  }

  loadStart() {
    this.isLoading = true;
  }

  loadedData() {
    this.isLoading = false;
  }

  loadError(err:any) {
    console.log("Error:", err);
    setTimeout(() => {
      this.showChannel(this.detailIndex);
    });
  }

  playAudio() {
    const audio = this.audioElementRef.nativeElement as HTMLAudioElement;
    // audio.load();
    audio.play()
        .catch(() => { /* discard runtime error */ });
  }

  pauseAudio() {
    const audio = this.audioElementRef.nativeElement as HTMLAudioElement;
    audio.pause();
  }
}