/**
 * @author Johann Kowalski (traal-devel) <devel@traal.ch>
 */


import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AssetsListResponse, AssetsService, ChannelProgram, DataParam, DataStreamResponse, EpgListResponse, LiveEventsService, QueryParamMap } from 'tvf-rest-client';

@Injectable({
  providedIn: 'root'
})
export class EpgService {


  /* member variables */
  private m_isCurrentDateDST: boolean = false;

  /* constructors */
  constructor(
    private assetsService: AssetsService,
    private liveEventService: LiveEventsService,
    private datePipe: DatePipe,
  ) { 
    this.m_isCurrentDateDST = this.isDST(new Date());
  }


  /* methods */
  getChannelList(): Observable<AssetsListResponse> {
    const filter = new DataParam();
    filter.$expand = '*';
    filter.$limit = 100;
    filter.$offset = 0;
    filter.$orderby = 'lcn';
    // filter.$match = 'SRF1';

    const params: QueryParamMap = new QueryParamMap();
    params.set('type', { eq: 'channel' });
    params.set('categories.ext_id', { eq: 'tv' });

    return this.assetsService.getAssetsList(
      filter,
      params,
      'en'
    );
  }

  getTvChannelListOrdered(): Observable<AssetsListResponse> {
    return this.assetsService.getPackageAssets(
      environment.package_tv,
      '*'
    );
  }

  getRadioChannelListOrdered(): Observable<AssetsListResponse> {
    return this.assetsService.getPackageAssets(
      environment.package_radio,
      '*'
    );
  }

  getChannelListOrdered(
    packageId: number,
    expand: string
  ): Observable<AssetsListResponse> {
    return this.assetsService.getPackageAssets(
      packageId,
      expand
    );
  }

  getCatchupStream(
    liveEventId: number
  ): Observable<DataStreamResponse> {
    return this.liveEventService.getCatchupStreamByLiveEventID(liveEventId);
  }

  getChannelStream(
    channelId: number, 
    streamId: number
  ): Observable<DataStreamResponse> {
    return this.assetsService.getChannelStream(channelId, streamId);
  }

  getNextThreeLiveEvents(
    channelId: number
  ): Observable<EpgListResponse> {
    const filter = new DataParam();
    filter.$expand = 'thumbnail(url),program';
    filter.$limit = 3;
    // filter.$match = 'SRF1';

    const params: QueryParamMap = new QueryParamMap();
    params.set('type', { eq: 'channel' });
    // params.set('start_time', { gt: new Date().toISOString() });
    params.set('end_time', { gt: new Date().toISOString() });
    params.set('live.id', { eq: channelId + '' });

    return this.liveEventService.getEpgList(
      filter,
      params,
      'en'
    );
  }


  getLiveEventAt(
    channelId: number,
    endTime: string,
  ): Observable<EpgListResponse> {
    const filter = new DataParam();
    filter.$expand = 'thumbnail(url),program';
    filter.$limit = 1;
    filter.$orderby = 'start_time';

    const tmpEndDate = this.datePipe.transform(endTime, 'yyyy-MM-ddTHH:mm:ssZ', 'UTC', 'de-ch');
    console.log(tmpEndDate.replace('+0000', 'Z'));

    const params: QueryParamMap = new QueryParamMap();
    params.set('type', { eq: 'channel' });
    // params.set('start_time', { gt: new Date().toISOString() });
    params.set('end_time', { gt: tmpEndDate.replace('+0000', 'Z') }); // :INFO: Workaround - Backend can not handle utc timezone in string. 
    params.set('live.id', { eq: channelId + '' });

    return this.liveEventService.getEpgList(
      filter,
      params,
      'en'
    );
  }

  /**
   * https://stackoverflow.com/questions/50135758/how-to-loop-through-subscribe-method-in-angular-5/50135906
   * 
   * @param idBulks 
   * @param pRefDate 
   * @returns 
   */
  getEpgOverviewListByBulks(
    idBulks: string[],
    pRefDate: Date
  ): Observable<EpgListResponse> {
    const filter = new DataParam();
    // filter.$expand = 'program';
    filter.$orderby = 'start_time';
    // filter.$limit = 1;

    const dYestarday = new Date();
    dYestarday.setDate(pRefDate.getDate() - 1);
    const dYesterdayFormatted = this.formatDateShort(dYestarday);

    const dTomorrow = new Date();
    dTomorrow.setDate(pRefDate.getDate() + 1);
    const dTomorrowFormatted = this.formatDateShort(dTomorrow);

    return from(idBulks)
            .pipe(
              mergeMap(idBulk => { 
                const params: QueryParamMap = new QueryParamMap();
                params.set('type', { eq: 'channel' });
                params.set('start_time', { lt: dTomorrowFormatted + 'T00:00:00Z' });
                params.set('end_time', { gt: dYesterdayFormatted + 'T23:59:59Z' }); // 2020-11-02T15:04:27.169Z"^
                params.set('live.id', {in: idBulk});

                return this.liveEventService.getEpgList(
                  filter,
                  params,
                  'en'
                );
              })
            );
  }

  getEpgOverviewList(
    pRefDate: Date
  ): Observable<EpgListResponse> {
    const filter = new DataParam();
    // filter.$expand = 'program';
    filter.$orderby = 'start_time';
    // filter.$limit = 1;

    const dYestarday = new Date();
    dYestarday.setDate(pRefDate.getDate() - 1);
    const dYesterdayFormatted = this.formatDateShort(dYestarday);

    const dTomorrow = new Date();
    dTomorrow.setDate(pRefDate.getDate() + 1);
    const dTomorrowFormatted = this.formatDateShort(dTomorrow);

    const params: QueryParamMap = new QueryParamMap();
    params.set('type', { eq: 'channel' });
    params.set('start_time', { lt: dTomorrowFormatted + 'T00:00:00Z' });
    params.set('end_time', { gt: dYesterdayFormatted + 'T23:59:59Z' }); // 2020-11-02T15:04:27.169Z"^
    
    // params.set('live.id', {in: '384132,384144'})
    // params.set('live.id', {in: '384132'});
    // params.set('live.id', {in: '382749,382752,382859,382861,382866,382873'});

    return this.liveEventService.getEpgList(
      filter,
      params,
      'en'
    );
  }

  formatDateShort(
    pDate: Date
  ): string {
    return this.datePipe.transform(pDate, 'yyyy-MM-dd');
  }

  isDST(pDate: Date) {
    let jan = new Date(pDate.getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date(pDate.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) != pDate.getTimezoneOffset(); 
  }

  isCurrentDateDST(): boolean {
    return this.m_isCurrentDateDST;
  }
}
